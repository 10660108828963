import dayjs from 'dayjs';
import queryString from 'query-string';
import 'react-quill/dist/quill.snow.css';
import { RefreshCw } from 'react-feather';
import { FC, useEffect, useMemo, useState } from 'react';
import { CheckCircleOutlined, CheckCircleTwoTone, CloseOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd';

import Api from '../../../api/index';
import { Images } from '../../../assets/images/Index';
import { BrandModel, LanguageModel, ProductFilter, VariantModel } from '../../../interfaces';
import { ProductImages } from './SectionsProducts/ProductImages';
import { VariantImages } from './SectionsProducts/VariantImages';
import { toastNotification } from '../../../utils/toastNotification';
import { ProductStatusEnum } from '../../../enums/ProductStatus.enum';
import { MappedAttribute } from './SectionsProducts/MappedAttributes';
import { ShopwareAttributes } from '../FilterAttributes/ShopwareAttributes';
import { ProductTranslateView } from './SectionsProducts/ProductTranslateView';
import { ProductTranslateModel } from '../../../interfaces/models/Product.model';
import { ShopProductCategories } from './SectionsProducts/ShopProductCategories';
import { LanguageEnum } from '../../../enums/Language.enum';
import { calculateTaxPrice } from '../../../utils';
import { ShopwareAttributesFilter } from '../FilterAttributes/ShopwareAttributesFilter';
import PriceField from './PriceField';

interface ViewProductNewDesignProps {
  handleGetAllProducts: (lng: string) => void;
  brands: BrandModel[] | [];
  shopBrands: BrandModel[] | [];
  searchValue?: string;
  setSearchValue?: (value: ProductFilter) => void;
  languages?: LanguageModel[] | [];
  shopCategories: any[] | [];
  shopAttributes?: any;
  shopId: number;
}

interface MappedState {
  changes: {
    group_id: string;
    attributes: string[];
  };
  shop_attributes: string[];
  shop_categories: string[];
}

type FileImageState = {
  id: string;
  url?: string;
  is_cover: boolean;
  productId?: string;
  is_variant?: boolean;
  new_image?: boolean;
  is_deleted?: boolean;
};

export const ViewProductNewDesign = ({
  languages,
  shopCategories,
  shopAttributes,
  shopBrands,
  handleGetAllProducts,
  shopId,
}: ViewProductNewDesignProps) => {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [product, setProduct] = useState<any | null>(null);
  const [mappedAttributes, setMappedAttributes] = useState<{
    list: any;
    show: boolean;
  }>({
    list: [],
    show: false,
  });

  // const [variantValidities, setVariantValidities] = useState<boolean[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [fileList, setFileList] = useState<FileImageState[]>([]);
  const [selectedSellerAttributes, setSelectedSellerAttributes] = useState<string[]>([]);
  const [selectedVendorBrand, setSelectedVendorBrand] = useState(product?.brand?.name);
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [mapedDelete, setMapedDelete] = useState<string[]>([]);
  const [translate, setTranslate] = useState<Partial<ProductTranslateModel> | null>(null);
  const [mapped, setMapped] = useState<MappedState>({
    changes: {
      group_id: '',
      attributes: [],
    },
    shop_attributes: [],
    shop_categories: [],
  });

  const [attributesGroup, setAttributesGroup] = useState<
    {
      id: string;
      checkable?: boolean;
      title: string;
      children: {
        id: string;
        icon: any;
        checkable?: boolean;
        is_mapped?: boolean;
        is_deleted?: boolean;
        variant_id: string;
        title: string;
        attribute_group_id: string;
      }[];
    }[]
  >([]);

  // hooks
  const { search } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const parsed = queryString.parse(search);

  const id = useMemo(() => parsed?.id, [parsed?.id]);

  const disabledMapButton =
    mapped?.changes?.attributes?.length === 0 && mapped?.shop_attributes?.length === 0;

  const categoriesForProduct = product?.product_categories.filter((category: any) =>
    product?.categories?.some(
      (productCategory: any) =>
        productCategory.category_id === category.id && !productCategory.is_deleted,
    ),
  );

  const handleSetError = (msg: string) => {
    toastNotification('error', msg);
    setLoading(false);
    setLoadingButton(false);
  };

  const handleCopy = (event: any) => {
    const clipboardData = event.clipboardData || window.Clipboard;
    const selectedText = window?.getSelection()?.toString().trim();

    clipboardData.setData('text/plain', selectedText);
    event.preventDefault();
  };

  const handleSetImages = (files: FileImageState[]) => {
    setFileList(files);
    form.setFieldValue('files', files);
  };

  const handleClose = () => {
    setIsEditable(false);
    setVisible(false);
    delete parsed['id'];
    delete parsed['translate'];
    navigate({ search: queryString.stringify(parsed) }, { replace: true });
    setMappedAttributes({
      list: [],
      show: false,
    });
    form.resetFields();
  };

  // const handleBarcodeChangeVariants = (value: string, index: number) => {
  //   const isValidBarcodeValue = isValidBarcode(value);
  //   setVariantValidities(prevValidities => {
  //     const newValidities = [...prevValidities];
  //     newValidities[index] = isValidBarcodeValue;
  //     return newValidities;
  //   });
  // };

  const isValidBarcode = (barcode: string) => {
    // Check if barcode is undefined or null
    if (barcode === undefined || barcode === null) {
      return false;
    }

    // GTIN must be 8, 12, 13, or 14 digits long
    if (!/^\d{8,14}$/.test(barcode)) {
      return false;
    }

    // Reverse the digits for processing
    const reverseGtin = barcode.split('').reverse().join('');
    let sum = 0;

    // Apply the modulo 10 algorithm
    for (let i = 0; i < reverseGtin.length; i++) {
      const digit = parseInt(reverseGtin[i], 10);
      if (i % 2 === 0) {
        // For positions 1, 3, 5, etc. (odd positions in the GTIN, even indexes in zero-based array)
        sum += digit;
      } else {
        // For positions 2, 4, 6, etc. (even positions in the GTIN, odd indexes in zero-based array)
        sum += digit * 3;
      }
    }

    // Check if the sum is a multiple of 10
    return sum % 10 === 0;
  };

  const removeHtmlTags = (str: string) => str?.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, '');
  const setSelectedRows = (data: any) => setMapped({ ...mapped, ...data });
  const handleSetCategories = (shop_categories: any) => setSelectedRows({ shop_categories });
  const handleSetAttributes = (shop_attributes: any) => setSelectedRows({ shop_attributes });

  const handleSetSellerAttributes = (shop_attributes: any) => {
    setSelectedSellerAttributes(shop_attributes);
  };

  const handleSubmit = (formData: any) => {
    const data = {
      ...formData,
      ...(formData?.shop_warranty != null && {
        shop_warranty: parseInt(formData.shop_warranty)
      }),
      product_images: fileList
        ?.filter(file => !file.is_variant)
        ?.map(file => ({
          ...file,
          ...(file?.new_image ? { remote_id: file.id } : { id: file.id }),
          ...(file?.is_deleted ? { is_deleted: true } : { is_deleted: false }),
        })),
      product_translates: [
        translate,
        ...(product?.product_translates?.filter(
          (item: { id: string }) => item.id !== translate?.id,
        ) || []),
      ].filter(item => item?.name),
      append_categories: mapped?.shop_categories,
      deleted_group_mapped: mapedDelete,
      ...(!formData?.brand_id && {
        brand_id: isBrandChanged ? selectedVendorBrand : product.brand.id,
      }),
    };

    /* eslint-disable camelcase */
    Api(`product/${id}`, 'PUT', { data }, { shopId })
      .then(({ statusMessage, statusIsOk }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          toastNotification('success', 'Suscessfully updated');
          handleGetAllProducts(LanguageEnum.EN);
          handleClose();
          setLoading(false);
          setMapped({
            changes: { group_id: '', attributes: [] },
            shop_attributes: [],
            shop_categories: [],
          });
          setMapedDelete([]);
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const handleGetProductById = () => {
    setLoading(true);
    Api(`product/${id}`, 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setProduct(result);
          setLoading(false);
          setMapedDelete([]);
          const variants =
            result?.variants?.map((variant: VariantModel) => ({
              ...variant,
              in_shop: !!variant?.in_shop,
              price: calculateTaxPrice(variant?.price, variant?.tvsh),
              discount_price: calculateTaxPrice(variant?.discount_price, variant?.tvsh)
            })) || [];

          const productImages = result.product_images
            .filter((image: FileImageState) => !image.is_deleted)
            .map((productImage: any) => ({
              id: productImage.id,
              is_cover: productImage.is_cover,
              is_variant: false,
              productId: result?.id,
              url: productImage.url,
            }));

          setFileList([...productImages]);

          form.setFieldsValue({
            ...result,
            ...(result?.brand?.mapped_brand && {
              mapped_brand_id: result?.brand?.mapped_brand?.shop_brand_id,
            }),
            ...(result?.brand && {
              brand_id: result?.brand?.id,
            }),
            variants,
          });
          setVisible(true);
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const handleSetMapProductShopAttributes = () => {
    Api(`variant/create-attribute/${id}`, 'POST', { attributes: selectedSellerAttributes }, { shopId })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          toastNotification('success', statusMessage);
          handleGetAttGroupsByProductId();
        }
      })
      .catch(({ statusMessage }) => {
        handleSetError(statusMessage);
      });
  };

  const handleGetAttGroupsByProductId = () => {
    Api(`mapped-attributes/getAttributeGroupsByProductId/${id}`, 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          const filtered = result?.map((item: any) => ({
            id: item?.id,
            key: item?.id,
            selectable: false,
            checkable: false,
            title: item?.original_name,
            children: item?.attributes?.map((attribute: any) => ({
              checkable: false,
              id: attribute.id,
              key: attribute.id,
              variant_id: attribute.variant_id,
              is_filter: attribute.is_filter,
              is_deleted: attribute.is_deleted,
              title: attribute?.original_name,
              ...(attribute?.is_mapped == 1 && {
                icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
              }),
            })),
          }));

          setAttributesGroup(filtered);
        }
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const getMappedAttributes = (mapped_id: string) => {
    setLoading(true);
    Api(`mapped-attributes/getMappedAttributes/${mapped_id}`, 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setMappedAttributes({
            list: result?.map((item: any, index: number) => ({
              ...item,
              key: item?.id.toString() + `${index}`,
            })),
            show: true,
          });
          setLoading(false);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handlSelectAttribute = (selected: string) => {
    setSelectedAttribute(selected);
    getMappedAttributes(selected);
    setMapped({
      ...mapped,
      changes: { attributes: [selected], group_id: mapped?.changes?.group_id },
    });
  };

  const handleDeleteMapped = (id: string) => {
    setLoading(true);
    Api(`mapped-attributes/${id}`, 'DELETE', null, { shopId })
      .then(({ statusIsOk, statusMessage, attribute_id: group_id }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          toastNotification('success', 'Successfully deleted mapping attributes');
          setSelectedAttribute('');
          handleGetAttGroupsByProductId();
          getMappedAttributes(group_id);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handleDeleteAttribute = (selectedAttributeId: string) => {
    if (selectedAttributeId) {
      Api(`variant/delete-mapped-attribute`, 'DELETE', {
        product_id: id,
        attribute_id: selectedAttributeId,
      }, { shopId })
        .then(({ statusIsOk, statusMessage }) => {
          if (!statusIsOk) handleSetError(statusMessage);
          if (statusIsOk) {
            toastNotification('success', statusMessage);
            handleGetAttGroupsByProductId();
          }
        })
        .catch(({ statusMessage }) => handleSetError(statusMessage));
    }
  };

  const handleMapFilterAttributes = () => {
    setLoadingButton(true);
    Api(`mapped-attributes`, 'POST', {
      seller_id: product?.seller_id,
      selected_attributes_ids: mapped?.changes?.attributes,
      checkedKeys: mapped?.shop_attributes,
    }, { shopId })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          toastNotification('success', 'Successfully mapping attributes');
          getMappedAttributes(selectedAttribute);
          handleGetAttGroupsByProductId();
          setMapped({
            ...mapped,
            shop_attributes: [],
          });
          setLoadingButton(false);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  useEffect(() => {
    if (id) {
      handleGetProductById();
      handleGetAttGroupsByProductId();
      setSelectedVariant(null);
      setIsBrandChanged(false);
      setFileList([]);
      // setVariantValidities([]);
      setSelectedSellerAttributes([]);
      setAttributesGroup([]);
      setMapped({
        changes: { group_id: '', attributes: [] },
        shop_attributes: [],
        shop_categories: [],
      });
    }
  }, [id]);

  return (
    <Drawer
      open={visible}
      title="View"
      width={window.innerWidth - 260}
      onClose={handleClose}
      className="view_product new__view-product"
      footer={
        <div className="user-action-button">
          <Button onClick={handleClose} className="seller-cancel-button">
            {isEditable ? 'Cancel' : 'Close'}
          </Button>
          <Button
            className="seller-save-button"
            type="primary"
            form="update-form"
            disabled={loading}
            htmlType="submit"
          >
            Update
          </Button>

          {isEditable && (
            <Button
              disabled={loading}
              form="product-form"
              className="save-button"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          )}
        </div>
      }
    >
      {visible ? (
        <Form
          form={form}
          className="form__wrapper-variant"
          onFinish={handleSubmit}
          id="update-form"
        >
          <div className="product-view-wrapper new__product-view-wrapper">
            <div className="info__wrapper">
              <div className="assigne__wrapper">
                <div className="content">
                  <p>Assigned</p>
                  <div>
                    {product?.users_products?.[0]?.first_name
                      ? `${product?.users_products[0]?.first_name} ${product?.users_products[0]?.last_name}`
                      : '-'}
                  </div>
                </div>
                <div className="content">
                  <p>Status</p>
                  <div>
                    {Object.entries(ProductStatusEnum)
                      .filter(([k]) => isNaN(Number(k)))
                      .map(
                        ([key, value]) =>
                          value == product?.status && <div key={key}>{key.replace('_', ' ')}</div>,
                      )}
                  </div>
                </div>
                <div className="last__updated">
                  Date added: {dayjs(product?.created_at).format('DD.MM.YYYY HH:mm') || '-'}
                </div>
                <div className="last__updated">
                  Last updated: {dayjs(product?.updated_at).format('DD.MM.YYYY HH:mm') || '-'}
                </div>
                <div className="last__updated">
                  Last Stock Change:{' '}
                  {dayjs(product?.stock_updated_at).format('DD.MM.YYYY HH:mm') || '-'}
                </div>
                <div className="last__updated">
                  In vendor list: {product?.is_in_vendor_list == 1 ? 'Yes' : 'No'}
                </div>
              </div>
              <div className="variant__view-color">
                <div className="product-info">
                  <ProductImages
                    productId={product?.id}
                    is_variant={false}
                    images={fileList || []}
                    setImages={handleSetImages}
                    handleGetAllProducts={handleGetAllProducts}
                    handleGetProductById={handleGetProductById}
                  />
                  <div className="data__wrapper">
                    <p className="info-title">Product name</p>
                    <Form.Item name="name">
                      <Input type="text" />
                    </Form.Item>
                  </div>

                  <div className="product__info">
                    <div className="data__wrapper">
                      <p className="info-title">SKU</p>
                      <p className="value" onCopy={handleCopy}>
                        {product?.sku || '-'}
                      </p>
                    </div>
                    <div className="data__wrapper">
                      <p className="info-title">SKU intern</p>
                      <p className="value" onCopy={handleCopy}>
                        {product?.sku_intern || '-'}
                        {!product?.is_simple && (
                          <img
                            src="https://static-00.iconduck.com/assets.00/variants-icon-512x512-hdqssuzp.png"
                            alt="Variants"
                            style={{ marginLeft: '5px', width: '16px', height: '16px' }}
                          />
                        )}
                      </p>
                    </div>
                    <div className="data__wrapper">
                      <p className="info-title">MPN</p>
                      <p className="value" onCopy={handleCopy}>
                        {product?.product_number || '-'}
                      </p>
                    </div>
                    <div className="data__wrapper">
                      <p className="info-title">CN Code</p>
                      <p className="value">
                        <Form.Item
                          name="cncode"
                          rules={[
                            {
                              pattern: /^[0-9]+$/,
                              message: 'Ju lutem vendosni vetëm numër',
                            },
                          ]}
                          // normalize={value => value.replace(/\D/g, '').trim()}
                        >
                          <Input type="text" />
                        </Form.Item>
                      </p>
                    </div>
                    <div className="data__wrapper">
                      <p className="info-title">Seller</p>
                      <p className="value" onCopy={handleCopy}>
                        {product?.seller?.name || '-'}
                      </p>
                    </div>
                    <div className="data__wrapper">
                      <p className="info-title">Sync</p>
                      <p className="value">
                        <div
                          className={
                            product?.status == '2' && product?.is_updated == true
                              ? 'not-synced'
                              : product?.status != '2'
                              ? 'not-in-shop'
                              : 'in-shop'
                          }
                        >
                          <RefreshCw size={16} />
                          {product?.status == '2' && product?.is_updated == true
                            ? 'Not synced'
                            : product?.status != '2'
                            ? 'Not in shop'
                            : 'In shop'}
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="description__wrapper">
                    <div className="data__wrapper">
                      <p className="info-title">Short description</p>
                      <p className="value">
                        {removeHtmlTags(product?.short_description?.trim()) || '-'}
                      </p>
                    </div>
                    <div className="data__wrapper">
                      <p className="info-title">Long description</p>
                      <p className="value">
                        {removeHtmlTags(product?.long_description?.trim()) || '-'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="product__info">
                  <div className="data__wrapper">
                    <p className="info-title">Vendor Brand</p>
                    <Select
                      size="large"
                      defaultValue={product?.brand?.name || '-'}
                      value={isBrandChanged ? selectedVendorBrand : product?.brand?.name}
                      showSearch={true}
                      optionFilterProp="children"
                      onChange={value => {
                        setSelectedVendorBrand(value);
                        setIsBrandChanged(true);
                        form.setFieldsValue({ mapped_brand_id: value });
                      }}
                    >
                      {shopBrands?.map((item: BrandModel, index: number) => (
                        <Select.Option
                          key={item.id?.toString() + `${index}`}
                          value={item.id}
                          label={item.name}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="data__wrapper">
                    <p className="info-title">Category</p>
                    <p className="value">
                      {categoriesForProduct?.map((item: any) => item.name).join(', ') || '-'}
                    </p>
                  </div>
                  <div className="data__wrapper">
                    <p className="info-title">Seller Warranty</p>
                    <p className="value">
                      {product?.vendor_warranty || '-'}
                    </p>
                  </div>
                  <div className="data__wrapper">
                    <p className="info-title">Foleja Warranty</p>
                    <Form.Item name="shop_warranty">
                      <Input type="number" min={1} />
                    </Form.Item>
                  </div>
                </div>

                <Card bordered={false} style={{ marginTop: '20px' }} className="card-variants">
                  <div className="data__wrapper data__wrapper-variants">
                    <p className="info-title">Variants</p>
                  </div>
                  <div>
                    <Form.List name="variants">
                      {fields => (
                        <>
                          {fields.map(({ key, name, ...restField }) => {
                            const variantsEAN = form.getFieldValue(['variants', name])?.ean;
                            return (
                              <Space
                                key={key}
                                style={{
                                  display: 'flex',
                                  marginBottom: 8,
                                  cursor: 'pointer',
                                  ...(selectedVariant === product.variants[name]?.id
                                    ? { backgroundColor: '#d9d9d9' }
                                    : {}),
                                }}
                                align="end"
                                size={4}
                                onClick={() =>
                                  setSelectedVariant(
                                    selectedVariant === product.variants[name]?.id
                                      ? null
                                      : product.variants[name]?.id,
                                  )
                                }
                              >
                                <Form.Item
                                  {...restField}
                                  {...(key == 0 && {
                                    label: 'SKU',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  className="read__variant"
                                  name={[name, 'sku']}
                                  normalize={value => value.replace(/\D/g, '').trim()}
                                >
                                  <Input
                                    readOnly
                                    placeholder="SKU"
                                    autoComplete="off"
                                    autoCorrect="off"
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  {...(key == 0 && {
                                    label: 'Sku Intern',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  name={[name, 'sku_intern']}
                                  className="read__variant"
                                >
                                  <Input
                                    placeholder="SKU Intern"
                                    readOnly
                                    autoComplete="off"
                                    autoCorrect="off"
                                  />
                                </Form.Item>
                                <>
                                  <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Tooltip
                                        placement="rightTop"
                                        title={
                                          isValidBarcode(variantsEAN)
                                            ? 'Valid Barcode'
                                            : 'Invalid Barcode'
                                        }
                                        className="more-info"
                                      >
                                        <div
                                          style={{
                                            width: '9px',
                                            height: '9px',
                                            borderRadius: '50%',
                                            backgroundColor: isValidBarcode(variantsEAN)
                                              ? 'green'
                                              : 'red',
                                            marginRight: '8px',
                                            marginTop: key === 0 ? '30px' : '-9px',
                                          }}
                                        ></div>
                                      </Tooltip>
                                    </div>
                                    <Form.Item
                                      {...restField}
                                      {...(key == 0 && {
                                        label: 'Ean',
                                      })}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      className="edit__variant-ean"
                                      name={[name, 'ean']}
                                      rules={[
                                        { required: false, message: 'Ju lutem vendosni SKU' },
                                      ]}
                                      normalize={value => value.trim()}
                                    >
                                      <Input
                                        // onChange={e =>
                                        //   handleBarcodeChangeVariants(e.target.value, name)
                                        // }
                                        placeholder="EAN"
                                        // readOnly={disableField}
                                        // className={disableField ? 'readOnly' : 'input-variants'}
                                        // className="input-variants"
                                        autoComplete="off"
                                        autoCorrect="off"
                                      />
                                    </Form.Item>
                                  </div>
                                </>

                                <Form.Item
                                  {...restField}
                                  {...(key == 0 && {
                                    label: 'W(cm)',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  name={[name, 'width']}
                                  className="edit__variant-cm"
                                  normalize={value => value.replace(/[^\d.-]/g, '').trim()}
                                >
                                  <Input
                                    placeholder="Width"
                                    // readOnly={disableField}
                                    // className={disableField ? 'readOnly' : 'input-variants'}
                                    // className="input-variants"
                                    autoComplete="off"
                                    autoCorrect="off"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, 'height']}
                                  {...(key == 0 && {
                                    label: 'H(cm)',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  normalize={value => value.replace(/[^\d.-]/g, '').trim()}
                                  className="edit__variant-cm"
                                >
                                  <Input
                                    autoComplete="off"
                                    autoCorrect="off"
                                    placeholder="Height"
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'depth']}
                                  {...(key == 0 && {
                                    label: 'D(cm)',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  normalize={value => value.replace(/[^\d.-]/g, '').trim()}
                                  className="edit__variant-cm"
                                >
                                  <Input autoComplete="off" autoCorrect="off" placeholder="Depth" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  {...(key == 0 && {
                                    label: 'Wt(kg)',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  name={[name, 'weight']}
                                  className="edit__variant-cm"
                                >
                                  <Input
                                    autoComplete="off"
                                    autoCorrect="off"
                                    placeholder="Weight"
                                  />
                                </Form.Item>

                                <PriceField index={name} form={form} restField={restField} id={key} shopId={shopId} />

                                <Form.Item
                                  {...restField}
                                  name={[name, 'stock']}
                                  {...(key == 0 && {
                                    label: 'Stock',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  normalize={value => value.replace(/[^\d.-]/g, '').trim()}
                                  className="read__variant-last"
                                >
                                  <Input
                                    autoComplete="off"
                                    autoCorrect="off"
                                    placeholder="Stock"
                                    readOnly
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'local_stock']}
                                  {...(key == 0 && {
                                    label: 'W.Stock',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  normalize={value => value.replace(/[^\d.-]/g, '').trim()}
                                  className="edit__variant-cm"
                                >
                                  <Input
                                    autoComplete="off"
                                    autoCorrect="off"
                                    placeholder="W.stock"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  {...(key == 0 && {
                                    label: 'Del.days',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  name={[name, 'max_handling_days']}
                                  className="read__variant-last"
                                >
                                  <Input
                                    autoComplete="off"
                                    autoCorrect="off"
                                    placeholder="Del.days"
                                    readOnly
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...(key == 0 && {
                                    label: 'Shop status',
                                  })}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  className="read__variant"
                                >
                                  {product.variants?.find((v: any, idx: number) => idx === name)
                                    ?.in_shop
                                    ? 'In shop'
                                    : 'Not in shop'}
                                </Form.Item>
                              </Space>
                            );
                          })}
                        </>
                      )}
                    </Form.List>
                    {selectedVariant && (
                      <div>
                        <VariantImages
                          images={
                            (selectedVariant &&
                              product?.variants?.find((x: VariantModel) => x.id === selectedVariant)
                                ?.variant_images) ||
                            []
                          }
                          handleGetAllProducts={handleGetAllProducts}
                          handleGetProductById={handleGetProductById}
                        />
                      </div>
                    )}
                  </div>
                </Card>
              </div>

              <Card bordered={false} style={{ marginTop: '20px' }} className="card-mapped-attrib">
                <div style={{ marginBottom: '20px' }}>
                  <h2 className="additional__filter-attributes">Mapped Attributes</h2>
                  {attributesGroup.length != 0 ? (
                    <div className="filter__mappingattribute-title">
                      <p>Attribute Group</p>
                      <p>Attribute Value</p>
                    </div>
                  ) : (
                    'No attributes in this product'
                  )}
                </div>
                <ViewAttributes
                  attributes={attributesGroup}
                  selectedVariant={selectedVariant}
                  selectedAttribute={selectedAttribute}
                  mappedAttributes={mappedAttributes}
                  handlSelectAttribute={handlSelectAttribute}
                  handleDeleteAttribute={handleDeleteAttribute}
                />
              </Card>
            </div>

            <div className="product-translated-info view-product-translate">
              <div className="second-form">
                <ProductTranslateView
                  product={product}
                  languages={languages}
                  translate={translate}
                  defaultLanguage={false}
                  setTranslate={setTranslate}
                />

                <div className="shopware_view_attributes shopware_view_attributes-new">
                  <Row gutter={16}>
                    <Col span={24} className="status_username brand-create">
                      <Form.Item
                        label="Shop Brand"
                        name="mapped_brand_id"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ message: 'Ju lutem vendosni brendin' }]}
                      >
                        <Select
                          size="large"
                          showSearch={true}
                          optionFilterProp="children"
                          placeholder="Shembull: Apple"
                          suffixIcon={<img src={Images.arrowCDown} />}
                          getPopupContainer={trigger => trigger.parentNode}
                        >
                          {shopBrands?.map((item: any, index: number) => (
                            <Select.Option
                              key={item.id?.toString() + `${index}`}
                              value={item.id}
                              label={item.name}
                            >
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <ShopProductCategories
                    enableFields={false}
                    product_id={id}
                    loading={false}
                    visible={visible}
                    categories={shopCategories}
                    handleSelect={handleSetCategories}
                  />
                  <div className="attribute_product_view">
                    {mappedAttributes?.show && (
                      <MappedAttribute
                        loading={loading}
                        mappedAttributes={mappedAttributes?.list}
                        handleDeleteMapped={handleDeleteMapped}
                      />
                    )}
                  </div>

                  <ShopwareAttributes
                    title="Shop attributes"
                    buttonName="Map"
                    attributes={shopAttributes}
                    loadingButton={loadingButton}
                    disabled={disabledMapButton}
                    setSelectedData={handleSetAttributes}
                    handleMapFilterAttributes={handleMapFilterAttributes}
                    hideMapSaveBtn={false}
                  />
                </div>
              </div>
              <div>
                <ShopwareAttributesFilter
                  title="Additional filter attributes"
                  visible={!!id}
                  buttonName="Add"
                  loadingButton={loading}
                  selectedShopAttributes={selectedSellerAttributes}
                  setSelectedData={handleSetSellerAttributes}
                  hideMapSaveBtn={id ? false : true}
                  handleMapFilterAttributes={handleSetMapProductShopAttributes}
                  sellerId={product.seller_id}
                />
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <div> Loading...</div>
      )}
    </Drawer>
  );
};

type ViewAttributesProps = {
  attributes: {
    id: string;
    checkable?: boolean | undefined;
    title: string;
    children: {
      id: string;
      icon: any;
      checkable?: boolean | undefined;
      is_mapped?: boolean | undefined;
      is_deleted?: boolean | undefined;
      variant_id: string;
      title: string;
      attribute_group_id: string;
    }[];
  }[];
  selectedVariant: string | null;
  selectedAttribute: string;
  mappedAttributes: {
    list: { id: string }[];
    show: boolean;
  };
  handlSelectAttribute: (selected: string) => void;
  handleDeleteAttribute: (selectedAttributeId: string) => void;
};

const ViewAttributes: FC<ViewAttributesProps> = ({
  attributes,
  selectedVariant,
  selectedAttribute,
  // mappedAttributes,
  handlSelectAttribute,
  handleDeleteAttribute,
}) => {
  return (
    <div className="mapped__attributes">
      {attributes?.map((item: any, index: number) => {
        const isSelected = item.children?.some((i: any) => i.id === selectedAttribute);

        return (
          <div
            className="attributes__group-title "
            key={item.id.toString() + `${index}`}
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderBottom: '1px solid #F0F2F5',
              padding: '5px',
            }}
          >
            <Popconfirm
              title={
                <div>
                  <p className="title">Are you sure you want to delete this attribute?</p>
                </div>
              }
              className={isSelected ? 'tooltip-btn' : 'tooltip-btn-hidden'}
              okText="Yes"
              cancelText="No"
              {...(isSelected && {
                onConfirm: () => handleDeleteAttribute(selectedAttribute),
              })}
            >
              <Tooltip title={isSelected ? 'Delete selected attribute' : ''}>
                <Button
                  type="text"
                  icon={<CloseOutlined size={12} style={{ color: '#FF0000' }} />}
                />
              </Tooltip>
            </Popconfirm>

            <p
              style={{
                maxWidth: '200px',
                width: '100%',
                textTransform: 'capitalize',
                fontSize: '14px',
                color: '#9d9b9b',
                padding: 0,
                margin: 0,
                marginRight: '10px',
                marginLeft: '10px',
              }}
            >
              {item.title}
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexFlow: 'row',
                maxWidth: '660px',
                width: '100%',
                overflow: 'hidden',
                flexWrap: 'wrap',
              }}
            >
              {item?.children
              .filter((child: any) => child.is_deleted !== 1)
              .map(
                (
                  atr: {
                    checkable: boolean;
                    id: string;
                    is_filter: number;
                    is_deleted: number;
                    key: string;
                    title: string;
                    variant_id: string;
                    icon: any;
                  },
                  idx: number,
                ) => {
                  const isSelectedVariant = atr.variant_id == selectedVariant;

                  const isFilter = atr ? atr.is_filter : null;
                  const isMapped = atr.icon;

                  const successColor = isMapped ? 'success' : 'default';
                  const isSelctedAtr = selectedVariant
                    ? isSelectedVariant
                    : selectedAttribute === atr.id;

                  return (
                    <Tag
                      key={atr.id.toString() + '-' + atr?.variant_id + '-' + idx + 1}
                      icon={isMapped ? <CheckCircleOutlined /> : <></>}
                      style={{ cursor: 'pointer', padding: '2px 6px', fontSize: '14px' }}
                      color={isSelctedAtr ? '#9d9b9b' : successColor}
                      closable={false}
                      onClick={e => {
                        e.preventDefault();
                        handlSelectAttribute(atr.id);
                      }}
                    >
                      {atr.title}
                      {isFilter === 0 && (
                        <div style={{ height: '3px', background: 'grey', marginBottom: '2px' }} />
                      )}
                    </Tag>
                  );
                },
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
