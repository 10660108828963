import ApiConstants from './ApiConstants';
import { toastNotification } from '../utils/toastNotification';
import { readAuthToken } from '../utils/authToken';
import { axiosApi } from '../utils/axiosApiInstance';

export default async function api(path: string, method: string, params: any, additionalHeaders = {}) {
  const token = readAuthToken();
  // const i18nextLng = localStorage.getItem('i18nextLng') || 'en';

  const customOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      ...additionalHeaders,
    },
    method,
    url: ApiConstants.BASE_URL + path,
    ...(params && { data: params }),
  };

  return axiosApi({ ...customOptions })
    .then((response: any) => Promise.resolve(response.data))
    .catch((error: any) => {
      if (error?.response?.status === 401) {
        toastNotification('info', 'Session expired, please login again!');
        localStorage.clear();
      } else {
        return Promise.reject(error?.response?.data);
      }
    });
}
